import React, { useRef } from 'react'
import { T } from '../../typo'
import { s, globals, colors, alpha } from '../../style'
import { WrapperSection } from '../../wrappers'
import { ButtonText } from '../../buttons'
import { useOnScreen } from '../../hooks'

const Workflow = ({ data, theme, useColor = true }) => {
  // console.log(data)
  const {
    buttonSmall,
    language,
    slug_section,
    // title,
    titleObject,
    description,
    workflows,
  } = data

  const refWorkflow = useRef(null)
  const onScreen = useOnScreen(refWorkflow)

  return (
    <WrapperSection id={slug_section} extraCss={{ contentVisibility: 'auto' }}>
      <div
        css={[
          sContainer,
          sTheme(theme),
          onScreen === false && {
            opacity: 0 /* contentVisibility: 'hidden' */,
          },
        ]}
        ref={refWorkflow}>
        <div>
          <T
            d={32}
            m={24}
            condensed
            bold
            variant={titleObject.variant}
            extraCss={[sText, useColor && { color: colors[theme].main }]}>
            {titleObject.text}
          </T>
          <T o={0.6} extraCss={[sText.description]}>
            {description}
          </T>
        </div>
        <div css={sWorkflows}>
          {workflows.map((workflow, id) => (
            <div key={id} css={[sWorkflow, sWorkflowTheme(theme)]}>
              <img
                src={workflow.image.publicURL}
                alt={workflow.title}
                css={sImageBackground}
              />
              <div css={sWorkflow.image}>
                <img src={workflow.image.publicURL} alt={workflow.title} />
              </div>
              <T
                d={24}
                m={20}
                condensed
                bold
                extraCss={[
                  sWorkflow.title,
                  useColor && { color: colors[theme].main },
                ]}>
                {workflow.title}
              </T>
              <T d={12} m={13} o={theme === 'dark' ? 0.6 : 0.8}>
                {workflow.description}
              </T>
            </div>
          ))}
        </div>
        <div css={sButton}>
          <ButtonText language={language} theme={theme} button={buttonSmall}>
            <span>{buttonSmall.text_before}</span>
            <span css={{ fontSize: '1rem' }}>{buttonSmall.text}</span>
          </ButtonText>
        </div>
      </div>
    </WrapperSection>
  )
}

const sContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  transition: 'opacity 0.5s ease-out',
  [s.sm_down]: {
    boxShadow: globals.shadows.sections.mobile,
    marginLeft: globals.spacing.outside.mobile,
    marginRight: globals.spacing.outside.mobile,
    borderRadius: globals.roundness.sections.mobile,
    padding: globals.spacing.inside.mobile,
    paddingBottom: 0,
  },
  [s.md]: {
    padding: globals.spacing.inside.desktop,
    paddingBottom: globals.spacing.inside.desktop / 2,
    boxShadow: globals.shadows.sections.desktop,
    borderRadius: globals.roundness.sections.desktop,
    margin: '0 auto',
  },
}

const sTheme = (theme) => ({
  backgroundColor: colors[theme].container,
})

const sText = {
  [s.sm_down]: { marginBottom: globals.spacing.inside.mobile },
  [s.md]: { marginBottom: globals.spacing.inside.desktop / 2 },
  description: {
    [s.sm_down]: { marginBottom: globals.spacing.inside.mobile },
    [s.md]: { marginBottom: (globals.spacing.inside.desktop * 2) / 3 },
  },
}

const sWorkflows = {
  display: 'flex',
  justifyContent: 'space-between',
  [s.sm_down]: { flexDirection: 'column' },
  // [s.sm]: { flexDirection: 'row' },
  [s.md]: {
    flexDirection: 'row',
    paddingBottom: globals.spacing.inside.desktop / 2,
  },
}

const sWorkflow = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexGrow: 0,
  [s.sm_down]: {
    borderRadius: globals.roundness.containers.mobile,
    padding: globals.spacing.inside.mobile,
    paddingBottom: globals.spacing.inside.mobile,
    marginBottom: globals.spacing.inside.mobile,
    width: '100%',
  },
  [s.md]: {
    borderRadius: globals.roundness.containers.desktop,
    padding: globals.spacing.inside.desktop / 2,
    paddingBottom: globals.spacing.inside.desktop,
    width: `calc(${100 / 3}% - ${globals.spacing.inside.desktop / 3}px)`,
  },

  title: {
    marginTop: globals.spacing.inside.desktop / 4,
    marginBottom: globals.spacing.inside.desktop / 4,
  },

  image: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: 64,
    marginTop: globals.spacing.inside.desktop / 4,
    marginBottom: globals.spacing.inside.desktop / 2,
    img: {
      maxWidth: 64,
      maxHeight: 64,
      width: 64,
      height: 64,
    },
  },
}

const sWorkflowTheme = (theme) => ({
  background: `linear-gradient(to bottom right, ${colors[theme].main.concat(
    alpha[2]
  )},${colors[theme].main.concat(alpha[12])} )`,
})

const sButton = {
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  [s.sm_down]: { paddingBottom: globals.spacing.inside.mobile },
}

const sImageBackground = {
  position: 'absolute',
  maxWidth: 100,
  maxHeight: 100,
  width: 100,
  height: 100,

  [s.sm_down]: {
    top: globals.spacing.inside.mobile,
    right: globals.spacing.inside.mobile,
    opacity: 0.04,
  },
  [s.md]: {
    top: globals.spacing.inside.desktop / 2,
    right: globals.spacing.inside.desktop / 2,
    opacity: 0.08,
  },
}

export default Workflow
